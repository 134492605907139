import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  main,
  mainRaised
} from "assets/jss/material-kit-pro-react.jsx";

import sectionCards from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const features = function(theme) {
  return {
    container,
    mlAuto,
    mrAuto,
    title,
    main,
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...modalStyle(theme),
    mainRaised,
    ...sectionCards,
    description,
    team: {
      padding: `50px 0px 30px 0px`
    },
    textCenter: {
      textAlign: `center`
    },
    infoArea: {
      marginBottom: `5%`
    },
    spaceInLink: {
      marginRight: `1%`
    },
    tableCell: {
      border: `1px solid black`,
      padding: "12px" ,  
      fontSize: "14px"
    },
    headCell: {
      fontSize:"15px",
      fontWeight:"bold",
      textShadow: "0px 0px 0px black"
    },
    root: {
      "@media (max-width:750px)": {
        width: '100%',
        overflowX: 'auto'
      }
    },
    exclusionFont:{
      "@media (max-width : 400px)": {
        fontSize:'1.0rem !important'
      },
    },
    table: {
      "@media (max-width:750px)": {
       paddingLeft: "5px",
       paddingRight: "5px",
       flex: "1 1 auto",
       maxHeight: "none",
       overFlowX: "auto",
      },
    },
    tableStyle: {
      marginLeft:"15px",
    }
  };
};

export default features;
