import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/covidPressReleaseStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
const financeByQuarter = ({ classes, data }) => {
  var clrImage = data.allStrapiImages.edges[1];
  return (
    <Layout
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
    <title>Covid Press Release | StarHealth.in</title>
  </Helmet>
  {process.env.ENABLE_HELMET == `true` && (
    <Helmet key="helmetTags">
      <link
        rel="canonical"
        href={url + `covid-press-release`}
      />
      <meta name="title" content="Covid Press Release | StarHealth.in" />
      <meta name="twitter:title" content="Covid Press Release | StarHealth.in" />
      <meta
        name="description"
        content="Covid Press Release"
      />
      <meta
        name="keywords"
        content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
      />
      <meta property="og:title" content="Covid Press Release | StarHealth.in" />
      <meta property="og:description" content="Covid Press Release" />
      <meta property="og:url" content={url + `covid-press-release`}/>
      <meta name="Covid Press Release | StarHealth.in" />
      <meta name="twitter:description" content="Covid Press Release" />
      <meta property="twitter:url" content={url + `covid-press-release`} />
    </Helmet>
  )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <div className={classes.team}>
                  <h2 className={classes.title}>Dear Esteemed Customer,</h2>
                </div>
              </GridItem>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.infoArea
                )}
              >
                <h5>
                  Please rest assured that, as always, Star Health Insurance is
                  with you in this fight against the pandemic novel corona virus
                  (COVID-19) and urges you to “Stay Home, Stay Safe”.
                </h5>
                <h5>
                  We understand your needs in these difficult times. We have a
                  well-established business continuity plan to ensure there is
                  minimal disruption to our services. Our Support services will
                  function with reduced staff and truncated timings due to the
                  complete lockdown announcement.
                </h5>
                <h5>
                  However, our Digital platforms will cater to most of your
                  health insurance needs. You can Buy, Renew or even submit your
                  Claim online.
                </h5>
                <h5>
                  Please Download our Customer app <strong>STAR POWER</strong>,
                  available on{` `}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.star.customer_app"
                    style={{ cursor: `pointer` }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Android Play Store</strong>
                  </a>{` `}
                  and{` `}
                  <a
                    href="https://apps.apple.com/us/app/star-power/id1477621177?ls=1"
                    style={{ cursor: `pointer` }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>IOS App Store</strong>
                  </a>{` `}
                  for a complete digital experience.
                </h5>
                <h5>Alternatively, you could use the below links:</h5>
                <div style={{ paddingLeft: `2%` }}>
                  <h5>
                    <a href="/health-insurance-plans" target="_self">
                      Buy a New Policy
                    </a>
                  </h5>
                  <h5>
                    <a href="/customerportal/instant-renewal/">Renew Policy</a>
                  </h5>
                  <a href="/claims#3">Check Claim Status</a>
                  <h5>
                    Submit reimbursement Claim documents : - soft copy ( Photo
                    or scan copy ) to:
                  </h5>
                  <h5>
                    <a
                      className={classes.spaceInLink}
                      href="mailto:claims.retail@starhealth.biz"
                    >
                      claims.retail@starhealth.biz
                    </a>
                    for Retail Reimbursement Claims
                  </h5>
                  <h5>
                    <a
                      className={classes.spaceInLink}
                      href="mailto:claims.gmc@starhealth.biz"
                    >
                      claims.gmc@starhealth.biz
                    </a>
                    for GMC Reimbursement Claims
                  </h5>
                  <h5>
                    <a
                      href="https://desk.zoho.in/portal/shaizohoin/newticket"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reach Star Health
                    </a>
                  </h5>
                  <h5>
                    <a href="/network-hospitals">Locate Network Hospitals</a>
                  </h5>
                  {/* <h5>
                    <a href="/coronavirus-insurance-policy">
                      Buy Star Covid-19 Insurance Plan
                    </a>
                  </h5> */}
                </div>
                <h5 style={{ marginTop: `3%` }}>
                  <strong>
                    STAY HOME – STAY SAFE – FOLLOW SOCIAL DISTANCE
                  </strong>
                </h5>
                <h5>Always at your Service</h5>
                <h5>Star Health Insurance – The Health Insurance Specialist</h5>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
financeByQuarter.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object
};
export default withStyles(customStaticPageStyle)(financeByQuarter);

export const covidPressReleaseQuery = graphql`
  query covidPressRelease {
    allStrapiImages(filter: { section: { category: { eq: "media" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
